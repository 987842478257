<template lang="pug">
  section.gemstone-jewelry
    .gemstone-jewelry__content.container.is-max-fullhd
      .gemstone-jewelry__wrapper
        h2.gemstone-jewelry__title {{$t('homePage.gemstoneJewelry.title')}}
        .gemstone-jewelry__jewelrys-block
          v-link.gemstone-jewelry__jewelry-block.jewelry-block(v-for="itemImg in itemImages" :key="itemImg.id", :to="itemImg.url")
            .jewelry-block__image
              v-image(:src="itemImg.src", :alt="$t(`homePage.gemstoneJewelry.${itemImg.name}`)")
            .jewelry-block__title {{ $t(`homePage.gemstoneJewelry.${itemImg.name}`) }}
</template>

<script>
export default {
  name: 'GemstoneJewelryComponent',

  computed: {
    itemImages() {
      return [
        {
          id: 'd290372e-e75b-4a5e-bcc0-af1ec31643ac',
          src: 'details/jewelry-rings@2x.png',
          name: 'rings',
          url: '/gemstone-rings/'
        },
        {
          id: '8dbea6e6-4085-42b5-a695-652f6f32b466',
          src: 'details/jewelry-earrings@2x.png',
          name: 'earrings',
          url: '/gemstone-earrings/'
        },
        {
          id: 'ad4d9e6d-169b-4d8e-8b74-fc5d07680d8e',
          src: 'details/jewelry-necklacesPendants@2x.png',
          name: 'necklacesPendants',
          url: '/gemstone-necklaces-pendants'
        },
        {
          id: 'f50884b5-d119-4557-ad30-63116f5d8111',
          src: 'details/jewelry-bracelets@2x.png',
          name: 'bracelets',
          url: '/gemstone-bracelets/'
        },
        {
          id: '9124eab1-a925-4d7e-868e-74ff4a7129aa',
          src: 'details/jewelry-mensRings@2x.png',
          name: 'mensRings',
          url: '/mens-gemstone-rings/?metals=5'
        }
        // {
        //   id: '8dfc7fa6-f167-49a8-9f7c-523179bc9e69',
        //   src: 'details/jewelry-cufflinks@2x.png',
        //   name: 'cufflinks',
        //   url: '/gemstone-cufflinks/'
        // },
        // {
        //   id: '1b943a6b-e3e8-429a-ba2f-e6167c9c4dc6',
        //   src: 'details/jewelry-brooches@2x.png',
        //   name: 'brooches',
        //   url: '/gemstone-brooches/'
        // }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.gemstone-jewelry
  padding-top: 130px
  padding-bottom: 130px
  position: relative
  +tablet-only
    padding-top: 80px
    padding-bottom: 80px
  +mobile
    padding-top: 54px
    padding-bottom: 54px
  &::before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: calc(52% - 10px)
    //background: $header-bg-color
    background: #f1efe9
    z-index: -1
    +touch
      height: 253px
    +mobile
      height: 217px
  &__wrapper
    text-align: center

  &__title
    font-family: $lora
    font-style: italic
    font-size: 34px
    line-height: 44px
    margin-bottom: 58px
    +touch
      //font-size: 32px
      //line-height: 42px
      margin-bottom: 40px
    +mobile
      margin-bottom: 32px
  &__link
    font-family: $lato
    font-size: 16px
    line-height: 24px
    display: inline-block
    margin-bottom: 60px
    +touch
      margin-bottom: 40px
    +mobile
      margin-bottom: 32px
  &__jewelrys-block
    display: grid
    //grid-template-columns: repeat(7, 1fr)
    grid-template-columns: repeat(5, 1fr)
    gap: 29px
    +tablet-only
      //grid-template-columns: repeat(4, 1fr)
      grid-template-columns: repeat(3, 1fr)
      grid-column-gap: 17px
      grid-row-gap: 40px
    +mobile
      grid-template-columns: repeat(2, 1fr)
      grid-column-gap: 20px
      grid-row-gap: 46px
  &__wrapper-button
    margin-top: 58px
    justify-content: center
    & .button
      max-width: 242px
      width: 100%
.jewelry-block
  text-align: center
  &__image
    margin: 0 auto 41px auto
    background-color: #f8f7f3
    max-width: 167px
    max-height: 167px
    +tablet-only
      margin: 0 auto 31px auto
      max-width: 157px
      max-height: 157px
    +mobile
      max-width: 169px
      max-height: 169px
      margin: 0 auto 20px auto
    ::v-deep
      img
        width: 100%
        aspect-ratio: 1 / 1
        +touch
          max-width: 100%
          aspect-ratio: 1 / 1

  &__title
    font-family: $lato
    font-weight: bold
    font-size: 18px
    line-height: 24px
    +mobile
      font-size: 16px
      line-height: 20px
</style>
